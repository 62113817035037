import {CollectionViewer, DataSource} from '@angular/cdk/collections';
import {QueryParamsModel} from '@app/core/models';
import {Observable, of, Subscription} from 'rxjs';

export abstract class AbstractDataSource<Model> implements DataSource<Model> {
  entities$: Observable<Model[]>;
  lastQuery$: Observable<QueryParamsModel>;

  loading$: Observable<boolean>;
  isPreloadTextViewed$: Observable<boolean> = of(true);

  // Paginator | Paginators count
  paginatorTotal$: Observable<number> = of(0);
  subscriptions: Subscription[] = [];

  connect(collectionViewer: CollectionViewer): Observable<Model[]> {
    return this.entities$;
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.subscriptions.forEach(sb => sb.unsubscribe());
  }
}
