import {Injectable} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {MatSnackBar, MatSnackBarRef} from '@angular/material/snack-bar';
import {
  ActionNotificationComponent,
  DeleteEntityDialogComponent,
  FetchEntityDialogComponent,
  UpdateStatusDialogComponent
} from '../../views/partials/content/crud';

export enum MessageType { Create, Read, Update, Delete}

@Injectable({providedIn: 'root'})
export class LayoutUtilsService {
  private readonly snackBar: MatSnackBar;
  private readonly dialog: MatDialog;

  constructor(snackBar: MatSnackBar, dialog: MatDialog) {
    this.snackBar = snackBar;
    this.dialog = dialog;
  }

  /**
   * Showing (Mat-Snackbar) Notification
   */
  showActionNotification(
    message: string,
    type: MessageType = MessageType.Create,
    duration: number = 5000,
    showCloseButton: boolean = true,
    showUndoButton: boolean = false,
    undoButtonDuration: number = 3000,
    verticalPosition: 'top' | 'bottom' = 'bottom'
  ): MatSnackBarRef<ActionNotificationComponent> {
    const data = {
      message,
      snackBar: this.snackBar,
      showCloseButton,
      showUndoButton,
      undoButtonDuration,
      verticalPosition,
      type,
      action: 'Undo'
    };
    return this.snackBar.openFromComponent(ActionNotificationComponent, {duration, data, verticalPosition});
  }

  openDialog<T>(dialog, data, options = {}): MatDialogRef<T> {
    return this.dialog.open(dialog, {data: data, width: '440px', ...options});
  }

  /**
   * Showing Confirmation (Mat-Dialog) before Entity Removing
   */
  deleteElement(
    title: string = '',
    description: string = '',
    waitDescription: string = ''
  ): MatDialogRef<DeleteEntityDialogComponent> {
    return this.dialog.open(DeleteEntityDialogComponent, {data: {title, description, waitDescription}, width: '440px', id: 'delete-entity-dialog'});
  }

  /**
   * Showing Fetching Window(Mat-Dialog)
   */
  fetchElements(data): MatDialogRef<FetchEntityDialogComponent> {
    return this.dialog.open(FetchEntityDialogComponent, {data, width: '400px'});
  }

  /**
   * Showing Update Status for Entities Window
   */
  updateStatusForEntities(
    title,
    statuses,
    messages
  ): MatDialogRef<UpdateStatusDialogComponent> {
    return this.dialog.open(UpdateStatusDialogComponent, {data: {title, statuses, messages}, width: '480px'});
  }
}
