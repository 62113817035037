import {AbstractDataSource} from '@app/core/datasources/abstract.datasource';
import {EntityCollectionService} from '@ngrx/data/src/entity-services/entity-collection-service';
import {map} from 'rxjs/operators';

export class CommonDatasource<E> extends AbstractDataSource<E> {
  constructor(entityService: EntityCollectionService<E>) {
    super();

    this.loading$ = entityService.loading$;
    this.isPreloadTextViewed$ = (entityService.selectors$ as any).showInitWaitingMessage$;
    this.paginatorTotal$ = (entityService.selectors$ as any).totalCount$;
    this.lastQuery$ = (entityService.selectors$ as any).lastQuery$.pipe(
      map((query: any) => {
        if (entityService.hasOwnProperty('defaultQueryParams')) {
          // @ts-ignore
          query = {...entityService.defaultQueryParams, ...query};
        }

        return {...query};
      })
    );

    this.entities$ = entityService.entities$;
  }
}
