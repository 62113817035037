import {SelectionModel} from '@angular/cdk/collections';
import {Component, OnInit} from '@angular/core';
import {BaseComponent} from '@app/views/partials/content/general/base-component';

@Component({template: ''})
// tslint:disable-next-line:component-class-suffix
export abstract class ComponentWithSelectionTable<Model> extends BaseComponent implements OnInit {
  selection = new SelectionModel<Model>(true, []);

  defaultColumns = ['select', 'actions'];
  displayedColumns = [];
  tableColumns: { id: string, title: string, order: number, displayed: boolean }[] = [];

  protected constructor() {
    super();
  }

  ngOnInit(): void {
    this.displayedColumns = this.getColumnsList();
  }

  toggleColumnDisplay(id: string): void {
    this.tableColumns.forEach((column: any) => {
      if (column.id === id) {
        column.displayed = !column.displayed;
      }
    });

    this.displayedColumns = this.getColumnsList();
  }

  isColumnDisplayed(id: string): boolean {
    return this.displayedColumns.indexOf(id) !== -1;
  }

  isAllSelected(rows): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = rows.length;
    return numSelected === numRows;
  }

  masterToggle(rows): void {
    if (this.selection.selected.length === rows.length) {
      this.selection.clear();
    } else {
      rows.forEach(row => this.selection.select(row));
    }
  }

  protected getColumnsList(): string [] {
    const columns = [...this.defaultColumns];
    const ids = [];

    this.tableColumns.forEach(value => {
      if (value.displayed) {
        ids.push(value.id);
      }
    });

    columns.splice(1, 0, ...ids);

    return columns;
  }
}
